.navigator-bars{
    display: block;
}
.sidebarActive{
    left: 0;
    transition: 500ms;
    animation: ease 1.s;
}
.sidebarPassive{
    left: -999px;
    transition: 500ms;
}
@media (min-width: 640px) {
    .navigator-bars{
        display: none;
    }
}