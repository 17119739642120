
.horizental-card-image{
    width:300px
}

.horizental-card-image{
    width:310px
}


@media (min-width: 640px) {
    .sm\:w-full {
        width: 100%;
    }
}