.container{
    width: 320px;
    height: auto;
    background: #fff;
    box-shadow: 0 10px 15px rgba(179, 179, 179, 0.7);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1rem;
}

.container h2{
    color: #0A66C3;
    font-size: 1.6rem;
}

.container h2 i{
    font-size: 1.8rem;
    padding-left: 0.1rem;
}
.intro-text p{
    font-size: 1.8rem;
    padding-top: 0.3rem;
}

.input{
    position: relative;
    width: 100%;
    height: 50px;
    margin-bottom: 0.7rem;
}

.input input{
    width: 100%;
    height: 50px;
    padding-top: 1.1rem;
    padding-left: 9px;
    outline: none;
    border: 1px solid #8c8c8c;
    border-radius: 3px;
    transition: 0.2s;
}

.input label{
    position: absolute;
    top: 30%;
    left: 10px;
    font-size: 1.1rem;
    color: #8c8c8c;
    transition: 0.2s;
}

.input input:focus ~ label, .input input:valid ~ label{
    top: 10%;
    font-size: 0.8rem;
    color: #000;
} 

.input input:focus{
    border-width: 2px;
    border-color: #0A66C3;
}



.forget-button{
    height: 50px;
    background: #0A66C3;
    outline: none;
    border: none;
    border-radius: 30px;
    color: #fff;
    font-size: 1rem;
    font-weight: bolder;
}
.container{
    width: 320px;
    height: auto;
    background: #fff;
    box-shadow: 0 10px 15px rgba(179, 179, 179, 0.7);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1rem;
}

.container h2{
    color: #0A66C3;
    font-size: 1.6rem;
}

.container h2 i{
    font-size: 1.8rem;
    padding-left: 0.1rem;
}
.intro-text p{
    font-size: 1.8rem;
    padding-top: 0.3rem;
}

.input{
    position: relative;
    width: 100%;
    height: 50px;
    margin-bottom: 0.7rem;
}

.input input{
    width: 100%;
    height: 50px;
    padding-top: 1.1rem;
    padding-left: 9px;
    outline: none;
    border: 1px solid #8c8c8c;
    border-radius: 3px;
    transition: 0.2s;
}

.input label{
    position: absolute;
    top: 30%;
    left: 10px;
    font-size: 1.1rem;
    color: #8c8c8c;
    transition: 0.2s;
}

.input input:focus ~ label, .input input:valid ~ label{
    top: 10%;
    font-size: 0.8rem;
    color: #000;
} 

.input input:focus{
    border-width: 2px;
    border-color: #0A66C3;
}



.forget-button{
    height: 50px;
    background: #0A66C3;
    outline: none;
    border: none;
    border-radius: 30px;
    color: #fff;
    font-size: 1rem;
    font-weight: bolder;
}
